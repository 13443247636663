<div class="p-grid">
    <div class="p-col-12 p-lg-9 p-md-12 p-sm-12" *ngIf="!topicFinished">
    
        <div #player_container id="playerone" class="player-container"
        *ngIf="!showQuiz"></div>
        <div >
            <!--p-progressBar [value]="elapsedPercenteage" [showValue]="false" (click)="progressbarClicked($event)"></p-progressBar-->                
            <p-slider *ngIf="!showQuiz" [ngModel]="elapsedTime" styleClass="p-m-3" [min]="subLessonStart" [max]="subLessonStop" (onSlideEnd)="onGoToSeconds($event.value)" (onChange)="onGoToSeconds($event.value)"></p-slider>
            <!--p>Elapsed time: {{elapsedTime}}</p>
            <p>Video duration: {{videoDuration}}</p-->
            <!--button pButton pRipple type="button" icon="pi pi-play" class="p-button-rounded p-button-outlined" (click)="play()" [disabled]="isPlay || topicFinished"></button>
            <button pButton pRipple type="button" icon="pi pi-pause" class="p-button-rounded p-button-secondary p-button-outlined" (click)="pausePlayer()" [disabled]="!isPlay || topicFinished"></button>
            <button pButton pRipple type="button" icon="pi pi-external-link" class="p-button-rounded p-button-success p-button-outlined" (click)="goFullscreen()"></button-->
            <div class="p-grid">
                <div class="p-col-6 p-lg-3 p-md-6 p-sm-6">
                    <button pButton pRipple type="button" icon="pi pi-play" class="p-button-rounded p-button-outlined whitebg" (click)="play()" [disabled]="isPlay || topicFinished"></button>
                    <button pButton pRipple type="button" icon="pi pi-pause" class="p-button-rounded p-button-outlined whitebg" (click)="pausePlayer()" [disabled]="!isPlay || topicFinished"></button>
                    <button pButton pRipple type="button" icon="pi pi-external-link" class="p-button-rounded p-button-outlined whitebg" (click)="goFullscreen()"></button>
                </div>
                <div class="p-col-6 p-lg-2 p-md-2 p-sm-6 p-md-offset-4 p-lg-offset-7">
                    <span class="whitetext">Volume</span><p-slider (onChange)="volumeSliderChange($event)" [(ngModel)]="playerVolume"></p-slider>
                </div>
            </div>
            <!--button pButton type="button" label="Go to 20s" (click)="setCurrentTime(20)"></button-->
        </div> 
    
    </div>
    <div class="p-col-12 p-lg-3 p-md-12 p-sm-12" *ngIf="!topicFinished">
        <app-notes [elapsedTime]="this.elapsedTime" [noteReached]="selectedNote" (noteSet)="onNoteSet($event)"
                   (noteRemoved)="onNoteRemoved($event)" (videoCommand)="onVideoCommand($event)"
                   (goToSeconds)="onGoToSeconds($event)" (noteUpdated)="onNoteUpdated($event)" [cuePointList]="cuePointList">
        </app-notes>
    </div>
    <div class="p-col-12 p-lg-12 p-sm-12" *ngIf="showQuiz">
        <app-quiz
                (nextLessonTopic)="goToNextLessonTopic()"
                (quizFinished)="quizFinished()"
                (restartLessonTopic)="restartLessonTopic()"
                [lessonTopic]="currentLessonTopic"
                [nextLessonTopicId]="nextLessonTopicId"></app-quiz>
        <!--p-card header="Advanced Card" subheader="Card Subheader" [style]="{width: '360px'}" styleClass="p-card-shadow">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae
                numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate
                neque
                quas!</p>
            <ng-template pTemplate="footer">
                <p-button label="Back to video" icon="pi pi-check" (click)="isCuePointReached=false"></p-button>
            </ng-template>
        </p-card-->
    </div>

    <!-- In case lessonTopic don't have quiz -->
    <div class="lesson-topic-finished-container" *ngIf="topicFinished && !showQuiz">
        <div class="p-grid buttons-container">
            <div class="p-col-12 p-lg-2 p-md-2 p-sm-12 p-md-offset-4 p-lg-offset-4" style="height: 12vh">
                <app-countdown-button
                        *ngIf="nextLessonTopicId"
                        [countdownEnabled]="true"
                        [label]="'Clicca per avanzare subito'"
                        [timerFormat]="'s'"
                        [duration]="10"
                        (timerOver)="goToNextLessonTopic()"
                        (buttonClicked)="goToNextLessonTopic()"></app-countdown-button>
                <p *ngIf="!nextLessonTopicId" style="text-align: center">Complimenti! Hai terminato tutte le lezioni.</p>
            </div>
            <div class="p-col-12 p-lg-2 p-md-2 p-sm-12" style="height: 12vh">
                <button pButton pRipple type="button"
                        label="Ricomincia lezione"
                        icon="pi pi-replay"
                        (click)="this.restartLessonTopic()"
                        style="width: 99%; margin: 0.5%; height: 100%">
                </button>
            </div>
        </div>
    </div>
   
    <!--div class="p-col-12">
        <app-notes [elapsedTime]="this.elapsedTime" [noteReached]="selectedNote" (noteSet)="onNoteSet($event)"
            (noteRemoved)="onNoteRemoved($event)" (videoCommand)="onVideoCommand($event)"
            (goToSeconds)="onGoToSeconds($event)" (noteUpdated)="onNoteUpdated($event)" [cuePointList]="cuePointList">
        </app-notes>
    </div-->
</div>

<p-toast></p-toast>

<!--p-dialog header="Note inserted" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p class="p-m-0">A NOTE WAS ADDED HERE. {{selectedNote.note}}
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog-->
