import {
  ElementRef,
  ViewChildren,
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  QueryList,
  OnDestroy
} from '@angular/core';
import { NoteModel, NoteRequestModel } from '@app/_models/note.model';
import Player from '@vimeo/player';
import { ActivatedRoute, Router } from '@angular/router';
import { CuePoint } from '@app/_models/cuepoint.model';
import { VimeoVideoOptions } from '@app/_models/vimeo-video-options.model';
import { LessonTopicService } from '@app/_services/lesson-topic.service';
import { NotesService } from '@app/_services/notes.service';
import { MessageService } from 'primeng/api';
import { LessonTopicModel } from '@app/_models/lesson-topic.model';
import { LessonsService } from '@app/_services';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('player_container') playerContainer;
  player: Player;
  elapsedTime = 0;
  elapsedPercenteage = 0;
  videoDuration = 0;

  // This will include the cuepoint list get from API attached to the video, treated as TEST POINT
  cuePointInitialized: number[] = [];

  cuePointList: CuePoint[] = [];

  displayModal = false;

  videoOptions: VimeoVideoOptions = new VimeoVideoOptions();

  selectedNote: CuePoint = new CuePoint();

  lessonIdFromParam = 0;

  videoIdFromParam = 0;
  oldvideoUrl = '';


  isPlay = false;

  subLessonStart = 0;
  subLessonStop = 0;
  isFullLengthVideo = false;

  topicFinished = false;
  showQuiz = false;

  playerVolume = 100;

  currentLessonTopic: LessonTopicModel = new LessonTopicModel();
  nextLessonTopicId = '';

  @ViewChildren('player_container') private itemElements: QueryList<ElementRef>;

  constructor(
    private route: ActivatedRoute,
    private lessonTopicService: LessonTopicService,
    private lessonsService: LessonsService,
    private notesService: NotesService,
    private messageService: MessageService,
    private router: Router) {
    this.route.params.subscribe((routeParams) => {

      this.topicFinished = false;
      this.showQuiz = false;
      this.cuePointInitialized = [];

      this.lessonIdFromParam = routeParams.lessonId;
      this.videoIdFromParam = this.lessonIdFromParam;

      if (this.player && this.isPlay) {
        this.pausePlayer();
      }
    });

  }

  ngOnInit(): void {
    //console.log('OnInit - VideoPlayer component');
  }

  ngOnDestroy() {
    //console.log('On destroy-Note Component');
  }

  ngAfterViewInit(): void {
    this.playerContainer = document.getElementById('playerone');

    // Subscribe for params change in URL
    this.route.params.subscribe(() => {
      this.lessonTopicService.getTopicById(this.lessonIdFromParam).subscribe(lessonTopic => {
        this.currentLessonTopic = lessonTopic;
        this.videoOptions = {
          width: 640,
          loop: false,
          responsive: true,
          controls: false,
          url: lessonTopic.videoUrl
        };

        if (!this.player) {
          this.player = new Player(
            this.playerContainer,
            this.videoOptions
          );
        }
        if (this.player) {
          if (this.videoOptions.url !== this.oldvideoUrl) {
            this.player.ready().then(() => {
              this.player.unload().then(() => {
                this.player.loadVideo(this.videoOptions.url)
                  .then(() => {
                    this.oldvideoUrl = this.videoOptions.url;
                    this.player.ready().then(() => {
                      this.player.pause().then(() => {
                        //console.log('loaded');
                        // The new video is loaded
                        this.cuePointList = [];
                        this.initializeVideo();
                        if (lessonTopic.start === lessonTopic.stop && lessonTopic.stop === 0) {
                          this.isFullLengthVideo = true;
                          lessonTopic.stop = this.player.getDuration().then(duration => {
                            lessonTopic.stop = duration;
                            this.initializePlayerTime(lessonTopic);
                          });
                        } else {
                          this.isFullLengthVideo = false;
                          this.initializePlayerTime(lessonTopic);
                        }
                      });
                    });
                  });
              });
            });
          } else {
            this.player.ready().then(() => {
              //console.log('Video loading skipped');

              this.player.pause().then(() => {
                // The new video is loaded
                this.cuePointList = [];
                this.initializeVideo();
                if (lessonTopic.start === lessonTopic.stop && lessonTopic.stop === 0) {
                  this.isFullLengthVideo = true;
                  lessonTopic.stop = this.player.getDuration().then(duration => {
                    lessonTopic.stop = duration;
                    this.initializePlayerTime(lessonTopic);
                  });
                } else {
                  this.isFullLengthVideo = false;
                  this.initializePlayerTime(lessonTopic);
                }
              });
            });
          }
        }

      }, (err) => {
        console.log(err);
      });
    });

    // this to subscribe for DOM changes.
    // This because the div of the video player is added/removed dinamically
    this.itemElements.changes.subscribe(() => {
      //console.log(
      //  'Item elements are now in the DOM!',
      //  this.itemElements.length
      //);

      const htmlElement = document.getElementById('playerone');

      this.player.destroy();
      this.player = new Player(htmlElement, this.videoOptions);
      this.playerContainer = htmlElement;

      // this to avoid initialization when video player is not on the DOM
      if (this.itemElements.length !== 0) {
        this.initializeVideo();
        this.setCurrentTime(this.elapsedTime);
      }
    });
  }

  initializePlayerTime(lessonTopic: LessonTopicModel) {
    this.subLessonStart = lessonTopic.start;
    this.subLessonStop = lessonTopic.stop;
    this.setCurrentTime(this.subLessonStart);
    this.elapsedTime = this.subLessonStart;

    this.topicFinished = false;
    this.showQuiz = false;
    this.play();
  }

  setCurrentTime(cuePoint) {
    //console.log('set', cuePoint);
    this.player.setCurrentTime(cuePoint + 0.1).then(f => {
      //console.log('SETTED', f);
      this.player.getCurrentTime().then(seconds => {
        // seconds = the current playback position
        //console.log('Current time from player', seconds);

        // Reset status when time set: used on lessonTopic without quiz
        if (Math.round(seconds) <= this.currentLessonTopic.stop) {
          this.topicFinished = false;
        }
      });
    }).catch(err => console.log('Error on setCurrentTime', err));
  }

  pausePlayerAndShowModal() {
    this.player.pause().then(() => {
      this.displayModal = true;
    });
  }

  pausePlayer() {
    this.player.pause().then(() => {
      this.isPlay = false;
    });

  }

  cuePointReached(data) {
    // check if the cuePoint is attached to a note
    let noteModel: NoteModel;
    noteModel = data.data;
    // If no note is set, the point is a cuePoint and i've to manage it (pausing the video, showing custom component)
    if (!noteModel.text) {
      /*this.isCuePointReached = true;
      this.isPlay = false;*/
    } else {
      // This to inform the note about the note reached
      this.selectedNote = data;
    }

    // If the topic is finished
    if (this.topicFinished) {
      document
        .exitFullscreen()
        .then(() => {
          this.pausePlayerAndShowModal();
        })
        .catch((err) => {
          console.error(err);
          this.pausePlayerAndShowModal();
        });
    }
  }

  play() {
    this.player.play().then(() => {
      this.isPlay = true;
    });

  }

  goFullscreen() {
    this.player.requestFullscreen();
  }

  initializeVideo() {
    this.isPlay = false;

    this.removeCallbacks();
    this.registerCallbacks();

    // Reset cuePointList and initialize cuePointOnVideo. This to avoid doubles in the local array.
    // This will return the cuepoint present in the video, so ONLY THE CUEPOINT INITIALIZED --- TO BE REVIEWED, REMEMBER IS ASYNC
    this.cuePointList = [];

    this.notesService.getNotesByTopicId(this.lessonIdFromParam).subscribe(r => {
      //console.log(r);
      r.forEach(np => {
        // create CuePoint model starting from retrieved Note if GLOBAL = FALSE
        if (!np.global) {
          const cuePoint = new CuePoint();
          cuePoint.data = np;
          this.addCuePointOnVideo(cuePoint);
        }
      });

      // Now put GLOBAL NOTES on top
      r.filter(res => res.global)
        .sort((a, b) => b.id - a.id)
        .forEach(res => {
          const cuePoint = new CuePoint();
          cuePoint.data = res;
          this.cuePointList.unshift(cuePoint);
          this.cuePointList = [...this.cuePointList];
        });
    });

    this.setVolume(this.playerVolume);
  }

  addCuePointOnVideo(cuePoint: CuePoint, saveOnDb: boolean = false) {
    if (cuePoint.data && cuePoint.data.lessonTopicId !== 0) {
      cuePoint.data.lessonTopicId = this.lessonIdFromParam;
    }
    this.player
      .addCuePoint(cuePoint.data.start, cuePoint.data)
      .then(id => {
        // cue point was added successfully
        // Normalize cuepoint start
        cuePoint.data.start = Math.floor(cuePoint.data.start);
        //console.log('note added', id);

        cuePoint.id = id;

        if (saveOnDb) {
          const noteToSave: NoteRequestModel = new NoteRequestModel();
          noteToSave.lessonTopicId = Number(this.videoIdFromParam);
          noteToSave.text = cuePoint.data.text;
          noteToSave.start = Math.floor(cuePoint.data.start);
          noteToSave.title = '';
          noteToSave.rating = 0;

          this.notesService.createNote(noteToSave).subscribe((r: NoteModel) => {
            cuePoint.data.id = r.id;
            this.messageService.add({severity: 'success', summary: 'Note created.', detail: ''});
            // if the cuepoint is a NOTE, add to cuePointList
            if (cuePoint.data.text != null) {
              this.cuePointList.push(cuePoint);
              this.cuePointList.sort((a, b) => a.data.start - b.data.start);
            }
          }, err => {
            console.error(err);
            this.messageService.add({severity: 'error', summary: 'Error on note creation.', detail: ''});
          });
        } else {
          // if the cuepoint is a NOTE, add to cuePointList
          if (cuePoint.data.text != null) {
            this.cuePointList.push(cuePoint);
            this.cuePointList = [...this.cuePointList].sort((a, b) => a.data.start - b.data.start);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  removeCuePointOnVideo(cuePoint: CuePoint, removeOnDb: boolean = false) {
    if (removeOnDb) {
      this.notesService.deleteNote(cuePoint.data.id).subscribe(res => {
        this.messageService.add({severity: 'success', summary: 'Note deleted.', detail: ''});
      }, err => {
        console.error(err);
        this.messageService.add({severity: 'error', summary: 'Error on note deletion.', detail: ''});
      });
    } else {
      //console.log('Skipping note db deletion');
    }
    this.player
      .removeCuePoint(cuePoint.id)
      .then(async id => {
        this.cuePointList = this.cuePointList.filter(cp => cp.id !== id);
        // cue point was removed successfully
        //console.log(
        //    'Cuepoint ' +
        //    id +
        //    ' removed. The size of the array is now ' +
        //    this.cuePointList.length,
        //    this.cuePointList
        //);
      })
      .catch((error) => {
        //console.log(error);
        this.messageService.add({severity: 'error', summary: 'Error on note deletion from video, please refresh.', detail: ''});
      });
    //console.log('Cuepointlist length', this.cuePointList.length);
  }

  async getCuePointList() {
    this.cuePointList = [];
    //console.log('Getting Cue Point List');
    await this.player.getCuePoints().then((cuePoints) => {
      this.cuePointList = cuePoints.filter((cp) => cp.data.text != null).sort((a, b) => a.data.start < b.data.start);
    });
  }

  onVideoCommand($event: string) {
    switch ($event) {
      case 'PAUSE': {
        this.pausePlayer();
        break;
      }
    }
  }

  onNoteSet($event: CuePoint) {
    //console.log('New note arrived: ', $event);
    this.addCuePointOnVideo($event, true);
  }

  async onNoteRemoved($event: CuePoint) {
    //console.log('Note removed: ', $event);
    this.removeCuePointOnVideo($event, true);

    // Remove cuepoint from array here in order to work also if the video is hidden
    this.cuePointList = this.cuePointList.filter((cp) => cp.id !== $event.id);
  }

  onNoteUpdated($event: CuePoint) {
    //console.log('Note updated: ', $event);
    this.removeCuePointOnVideo($event);
    this.addCuePointOnVideo($event);
    this.notesService.updateNote($event.data).subscribe(() => {
      this.messageService.add({severity: 'success', summary: 'Note updated.', detail: ''});
    }, err => {
      console.error(err);
      this.messageService.add({severity: 'error', summary: 'Error on note update.', detail: ''});
    });
  }

  onGoToSeconds($event) {
    if (this.player){
      this.player.pause().then(() => {
        this.isPlay = false;
        this.setCurrentTime($event);
      });
    }
    else{
      this.setCurrentTime($event);
    }
  }

  registerCallbacks() {
    this.player.on('fullscreenchange', f => {
      //console.log('Fullscreen mode changed!');
    });

    this.player.on('ended', f => {
      this.lessonTopicOver();
    });

    this.player.on('timeupdate', seconds => {
      if ((seconds.seconds >= this.subLessonStop) && !this.isFullLengthVideo) {
        this.lessonTopicOver();
      }
      this.elapsedTime = seconds.seconds;
      this.elapsedPercenteage = Math.round(
        (this.elapsedTime / this.videoDuration) * 100
      );
    });

    this.player.on('cuepoint', data => {
      this.cuePointReached(data);
    });

    this.player.on('loaded', data => {
      this.player.getDuration().then((r) => {
        //console.log('Video duration', r);
        this.videoDuration = r;
      });
    });
  }

  lessonTopicOver() {
    this.nextLessonTopicId = this.lessonsService.getNextLessonTopicId(this.lessonIdFromParam);

    // Set current lesson topic as viewed
    this.lessonsService.setLessonTopicAsViewed(this.lessonIdFromParam);

    if (this.currentLessonTopic.quiz) {
      this.showQuiz = true;
    }
    document
      .exitFullscreen()
      .then(() => {
        this.pausePlayerAndShowModal();
        this.topicFinished = true;
      })
      .catch((err) => {
        this.pausePlayerAndShowModal();
        this.topicFinished = true;
      });
    this.topicFinished = true;
  }

  removeCallbacks() {
    this.player.off('fullscreenchange');
    this.player.off('timeupdate');
    this.player.off('cuepoint');
    this.player.off('ended');
  }

  volumeSliderChange($event) {
    this.setVolume($event.value);
  }

  setVolume(value) {
    this.player.setVolume(value / 100).then(() => {
      // volume was set
    });
  }

  quizFinished() {
    this.topicFinished = false;
    this.showQuiz = true;
  }

  goToNextLessonTopic() {
    const next = this.lessonsService.getNextLessonTopicId(this.lessonIdFromParam);
    this.router.navigate(['/video-player/' + next]);
  }

  restartLessonTopic() {
    this.setCurrentTime(this.subLessonStart);
    this.elapsedTime = this.subLessonStart;
    this.topicFinished = false;
    this.showQuiz = false;
    this.play();
  }
}
